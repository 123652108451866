@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/gvw2dsd.css?s=1");

html {
	@apply bg-slate-700;
}

.ql-container {
	@apply h-48 !important;
}

.quill span {
	color:white;
}

.quill .ql-toolbar {
	@apply border-slate-600 !important;
}
.quill .ql-container {
	@apply border-slate-600 !important;
	@apply bg-slate-600;
	@apply text-slate-100;
}
.quill svg {
	@apply text-white;
	@apply fill-white !important;
	filter: invert(100%) brightness(119%) contrast(119%);
}